//const urlPointer = 'https://fancybackend.onrender.com';
//const urlPointer = 'http://localhost:4000';
//const urlPointer = 'http://13.53.212.161';
// urlPointer = 'http://ec2-13-53-212-161.eu-north-1.compute.amazonaws.com';
//const urlPointer = 'https://vast-tan-hen-kilt.cyclic.app/';
const urlPointer = 'https://fancybackenddom.com.ng'

const defaultBodyStyles={
    backgroundColor : '#000000',
    padding: 0,
    margin: 0
   
}
export{
    urlPointer,
    defaultBodyStyles
} 